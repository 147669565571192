import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Cookie } from "ng2-cookies";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LocalStorageKeys } from "@models/LocalStorageKeys";
import { ContextService } from "@services/context.service";
import { catchError, map, tap } from "rxjs/operators";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/core/util-services/local-storage.service";
import { Credentials } from "../../features/login/models/credentials";
import { environment } from "../../../environments/environment";
import { Error } from "../../features/account-status/models/Error";

@Injectable()
export class AuthService {
    error: Error;
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    private isFirstLoginSubject = new BehaviorSubject<boolean>(false);
    private refreshTokenTimeout;
    tokenCheckInterval;

    // TODO: Move this to backend later
    private REALM = "brame";
    private CLIENT_ID = "fe-client";
    private CLIENT_SECRET = "6bea312f-cbed-43cc-8c46-599e90124542";

    isFirstLogin$: Observable<boolean>;

    constructor(
        private localStorageService: LocalStorageService,
        private contextService: ContextService,
        private http: HttpClient,
        private router: Router
    ) {
        this.isFirstLogin$ = this.isFirstLoginSubject.asObservable();
    }

    get isLoggedIn() {
        const token = this.getToken();
        this.loggedIn.next(token !== null && !this.tokenExpired(token));
        return this.loggedIn.asObservable();
    }

    tokenExpired(token: string): boolean {
        const helper = new JwtHelperService();
        return helper.isTokenExpired(token);
    }

    getTokenExpirationDate(token: string) {
        const helper = new JwtHelperService();
        return helper.getTokenExpirationDate(token);
    }

    getToken(): string {
        return Cookie.get(environment.cookies.brame_at);
    }

    getRefreshToken(): string {
        return Cookie.get(environment.cookies.refresh_brame_at);
    }

    setCookie(name: string, value: string): void {
        Cookie.set(name, value, 14, "/");
    }

    logout(): void {
        Cookie.delete(environment.cookies.brame_at, "/");
        Cookie.delete(environment.cookies.refresh_brame_at, "/");
        this.localStorageService.removeItem(environment.contextKeyName);
        this.localStorageService.removeItem(LocalStorageKeys.USER_TYPE);
        this.localStorageService.removeItem(LocalStorageKeys.USER_ROLE);
        this.stopRefreshTokenTimer();
        if (this.tokenCheckInterval) {
            clearInterval(this.tokenCheckInterval);
        }
    }

    checkTokenExpiration() {
        this.tokenCheckInterval =  setInterval(() =>{
            const token = this.getToken();
            if (!token || this.tokenExpired(token)) {
                this.logout();
                void this.router.navigate(["/login"]);
            }
        }, 60 * 1000);
    }

    keyCloakLogin(credentials: Credentials): Observable<any> {
        this.logout();
        const data: any = {
            username: credentials.username,
            password: credentials.password,
            clientId: this.CLIENT_ID,
            clientSecret: this.CLIENT_SECRET,
            grantType: "password",
            tokenUri:
                environment.keyCloakApiUrlPrefix +
                `/auth/realms/${this.REALM}/protocol/openid-connect/token`,
        };

        if (credentials.otpCode) {
            data.otpCode = credentials.otpCode;
        }

        return this.http
            .post(environment.apiUrlPrefix + "/user-management/users/log-in", data)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.handleError(response);
                    return of(response);
                }),
                tap((response) => {
                    if (!!response.firstLogin) {
                        this.isFirstLoginSubject.next(true);
                    }

                    return of(response);
                })
            );
    }

    refreshToken() {
        const body = new HttpParams()
            .set("client_id", this.CLIENT_ID)
            .set("client_secret", this.CLIENT_SECRET)
            .set("grant_type", "refresh_token")
            .set("refresh_token", this.getRefreshToken());

        return this.http.post(environment.keyCloakApiUrlPrefix + `/auth/realms/${this.REALM}/protocol/openid-connect/token`,
            body.toString(),
            {
                headers: new HttpHeaders().set(
                    "Content-Type",
                    "application/x-www-form-urlencoded"
                ),
            }
            // eslint-disable-next-line @typescript-eslint/naming-convention
        )
            .pipe(
                map(
                    (response: {
                        access_token: string;
                        refresh_token: string;
                    }) => {
                        this.setCookie(
                            environment.cookies.brame_at,
                            response.access_token
                        );
                        this.setCookie(
                            environment.cookies.refresh_brame_at,
                            response.refresh_token
                        );
                        this.startRefreshTokenTimer();
                    }
                ),
                catchError((response) => {
                    if (response.status === 400) {
                        this.logout();
                        void this.router.navigate(["/login"]);
                        return of(response);
                    }
                })
            );
    }

    startRefreshTokenTimer() {
        // set a timeout to refresh the token a minute before it expires
        const expires = this.getTokenExpirationDate(this.getToken());
        const timeout = expires.getTime() - Date.now() - 60 * 1000;
        this.refreshTokenTimeout = setTimeout(
            () => this.refreshToken().subscribe(),
            timeout
        );
    }

    checkOTPConfiguration(email: string) {
        return this.http
            .get(`${environment.apiUrlPrefix}/user-management/users/check-otp/${email}`)
            .pipe(
                catchError(() => of(false)),
                map(
                    (response: { data: boolean; message: string }) =>
                        response.data
                )
            );
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    private handleError(response: HttpErrorResponse) {
        const { status, error } = response;
        this.error = {
            status,
            message:
                (error &&
                    error.violations &&
                    error.violations.length &&
                    error.violations[0].message) ||
                "",
        };
        // TODO this is to be refactored when we get a login design
        // this.router.navigateByUrl('/account-status');
    }
}
